import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { injectIntl} from "gatsby-plugin-intl";

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const H2 = styled.h2`
    font-size: 25px;
    width: 100%;
    margin-top: 10px;
}`;

const P = styled.p`
    margin-top: 5px;
    font-size: inherit;
}`;

const CookiesPolicy = ({ data }) =>  ({
 
    render(){
            return(
                <Layout>
                    <SEO title="Privacy Policy" />

                            <section>
                                {/*<BackgroundImage Tag="section"
                                    fluid={data.placeholderImage.childImageSharp.fluid}
                                    className="page-top-section"
                                >
                                    <div className="container text-white">
                                        <h2>AVISO LEGAL</h2>
                                    </div>
                                </BackgroundImage>*/}

                                <div className="site-breadcrumb">
                                    <div className="container">		
                                    </div>
                                </div>

                                <section className="page-section blog-page">
                                <div className="container">
                                    <TextJustified className="row" id="privacy-policy">
                                        <H1><b><span>AVISO LEGAL (Acuerdo de Usuario): </span></b></H1>

                                        <H2><b><u><span>Datos identificativos: </span></u></b></H2>

                                        <P><span>En cumplimiento del artículo 10 de la Ley 34/2002 de 11 de julio, Ley de Servicios de la Sociedad
                                        de la Información y Comercio Electrónico (LSSI-CE), este sitio web es titularidad de <b>Visaverde Consultancy Services S.L.</b> <span>&nbsp;</span>(en adelante Visaverde), con dirección en Calle Alemania número 11, CC Patio Canario, local número 4, San Eugenio Alto, 38660 Adeje, con NIF B-38417069 en inscrita en el Registro Mercantil de Tenerife TF-12.356, folio 49 del Tomo 1373 de la sección General, inscripción 2ª, con número de teléfono +34 922 714 290 y cuenta de correo electrónico <b>info@visaverde.com</b></span></P>

                                        <H2><b><u><span>Objeto y aceptación: </span></u></b><i><span></span></i></H2>

                                        <P><span>Este aviso legal tiene
                                        por objeto regular las condiciones de uso y navegación del Sitio Web por parte del
                                        Usuario. En este sentido, el acceso y utilización del mismo implican la
                                        aceptación plena sin reservas de todas y cada una de las disposiciones
                                        incluidas en él, haciendo un uso correcto de acuerdo con la normativa vigente,
                                        respondiendo ante <b><i>Visaverde</i></b><i> </i>y frente a
                                        terceros, de los daños y perjuicios que pudieran causarse por dicho
                                        incumplimiento.</span></P>

                                        <H2><b><u><span>Información sobre el contenido: </span></u></b></H2>

                                        <P><b><i><span>Visaverde</span></i></b><span>,
                                        realiza revisiones periódicas de los contenidos e información publicados en el
                                        Sitio Web, sin embargo, pueden no ser exhaustivos o no estar actualizados, por
                                        lo que la <b><i>Visaverde</i></b> no asume responsabilidad alguna respecto de la falta
                                        de actualización o exactitud de la información, datos o contenidos publicados
                                        en el mismo. <b><i>Visaverde</i></b> tampoco puede controlar el empleo que el Usuario da a
                                        la información publicada <span>y</span> por tanto, no será
                                        responsable de ningún tipo de daños o perjuicios, ya sean directos o indirectos,
                                        que puedan derivar del empleo de dicha información.</span></P>

                                        <H2><b><u><span>Propiedad Intelectual e Industrial: </span></u></b></H2>

                                        <P><span>Todos los elementos del
                                        Sitio Web que sean susceptibles de contar con la protección de los derechos de
                                        propiedad intelectual o industrial, son titularidad exclusiva de <b><i>Visaverde</i></b>
                                        o bien dispone de autorización o licencia respecto de los titulares de sus
                                        derechos de explotación. Todos los nombres comerciales, marcas o signos
                                        distintivos, logotipos, símbolos, marcas mixtas, figurativas o nominativas que
                                        aparecen en este Sitio Web pertenecen a <b><i>Visaverde</i></b> o dispone, previa
                                        autorización, del derecho de uso sobre las mismas y se encuentran protegidos
                                        por la legislación vigente al respecto.</span></P>

                                        <P><span>La extracción y uso de elementos del Sitio Web causando o no un perjuicio cualquiera a <b><i>Visaverde</i></b>,
                                        conforme a las disposiciones del Real Decreto Legislativo 1/1996, de 12 de
                                        abril, por el que se aprueba el Texto Refundido de la Ley de Propiedad
                                        Intelectual y la Ley 5/1998, de 6 de marzo, de Incorporación al Derecho Español
                                        de la Directiva 96/9/CE sobre la Protección Jurídica de las Bases de Datos,
                                        quedan totalmente prohibidas.</span></P>

                                        <H2><b><u><span>Legislación aplicable y resolución: </span></u></b></H2>

                                        <P><span>Las presentes
                                        condiciones se rigen por la legislación española. Para la resolución de
                                        cualquier controversia o cuestión respecto del Sitio Web o de las presentes
                                        condiciones legales serán resueltas ante los Juzgados y Tribunales <b><i>domicilio
                                        de Visaverde</i></b>. En caso de que el Usuario tenga la condición de
                                        Consumidor o Usuario de acuerdo a las definiciones del Texto Refundido de la
                                        Ley General para la Defensa de Consumidores y Usuarios (Real Decreto
                                        Legislativo 1/2007, de 16 de noviembre), serán resueltas ante los Juzgados y
                                        Tribunales del domicilio del Usuario.</span></P>    
                                    </TextJustified>
                                </div>
                                </section>
                            </section>
                </Layout>
            )
        }

    })

export default injectIntl(CookiesPolicy)
export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`